const $ = window.jQuery;

$(document).ready(function(){

  $(".blackroll-dropdown").hover(function(){
    $('.blackroll-dropdown-menu').css('display', 'block');
  }, function () {
    $('.blackroll-dropdown-menu').css('display', 'none');
  });  



  // code to disable email field in profile view
  const target = document.querySelector("#chPersonalInfoForm input[name=email]");
  if (target) {
    target.disabled = true  
  }
})